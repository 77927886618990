<!--账号管理-->
<template>
    <div>
        <a-row class="searchBox flex flex-jc-sb">
            <a-input-search v-model:value="param.search" placeholder="输入姓名/电话" style="width: 320px;" enter-button="搜索" @search="filterChange" />
            <a-button type="primary" @click="handleEdit()">添加账号</a-button>
        </a-row>

        <a-row class="tableBox">
            <a-table :dataSource="list" :columns="columns" rowKey="id" :pagination="false" :scroll="{x: 1000, y: 'calc(100vh - 390px)' }">
                <template #number="{text, record, index}">
                    <span>{{ (param.curr - 1) * param.row + index + 1 }}</span>
                </template>
                <template #operation="{ record }">
                    <a-space>
                        <a-button type="primary" @click="handleEdit(record)">编辑</a-button>
                        <a-button type="primary" @click="handleDelete(record)">删除</a-button>
                    </a-space>
                </template>
            </a-table>
            <div class="flex flex-jc-fe Pageing">
                <TablePagination :curr="param.curr" :row="param.row" :count="count" @changeTableRequest="changeTableRequest" />
            </div>
        </a-row>

        <!--添加学生-->
        <DialogAssembly ref="DialogRef" :title="title" @handleConfirm="handleConfirm" @handleCancle="handleCancle">
            <a-form ref="formRef" :model="Form" :rules="rules" layout="vertical">
                <a-form-item label="姓名" name="realname">
                    <a-input v-model:value="Form.realname" placeholder="请输入姓名" />
                </a-form-item>
                <a-form-item label="手机号" name="mobile">
                    <a-input v-model:value="Form.mobile" placeholder="请输入手机号" />
                </a-form-item>
                <a-form-item label="用户名" name="username">
                    <a-input v-model:value="Form.username" placeholder="请输入用户名" />
                </a-form-item>
                <a-form-item label="密码" :name="Form.id ? '' : 'pwd'">
                    <a-input-password v-model:value="Form.pwd" placeholder="请输入密码" />
                </a-form-item>
            </a-form>
        </DialogAssembly>
    </div>
</template>
<script>
import { defineComponent, reactive, toRefs, ref, getCurrentInstance, onMounted } from 'vue';
import TablePagination from '@/components/TablePagination';
import DialogAssembly from '@/components/DialogAssembly';
import useDelete from '@/hooks/useDelete.js';
import md5 from 'js-md5';

export default defineComponent({
    components: {
        TablePagination,
        DialogAssembly
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const { useDeleteFun } = useDelete();
        const DialogRef = ref();
        const formRef = ref();
        onMounted(() => {
            getList();//学生列表
        });
        const initData = reactive({
            list: [],//账号列表
            columns: [
                { title: '序号', dataIndex: 'number', width: 80, slots: { customRender: 'number' } },
                { title: '姓名', dataIndex: 'realname' },
                { title: '电话', dataIndex: 'mobile' },
                { title: '用户名', dataIndex: 'username' },
                { title: '操作', fixed: 'right', width: 180, slots: { customRender: 'operation' } },
            ],
            param: {
                curr: 1,
                row: 10
            },
            Form: {},
            rules: {
                realname: [{
                    required: true,
                    message: '请输入姓名',
                }],
                mobile: [{
                    required: true,
                    message: '请输入手机号',
                    pattern: /^1[3-9]\d{9}$/,
                }],
                username: [{
                    required: true,
                    message: '请输入用户名',
                }],
                pwd: [{
                    required: true,
                    message: '请输入密码',
                }],
            },
            count: 0,
            pages: 1,
            title: '新建账号'
        });

        const getList = () => {
            proxy.http.post('/admin.admin/index', initData.param).then(response => {
                initData.list = response.list;
                initData.count = response.count;
                initData.pages = response.pages;
            })
        };

        //分页change
        const changeTableRequest = (event, value) => {
            if (event == 'curr') {
                initData.param.curr = value;
            } else {
                initData.param.curr = 1;
                initData.param.row = value;
            }
            getList();
        };

        const filterChange = () => {
            initData.list = [];
            initData.param.curr = 1;
            getList();
        };

        //新建弹框
        const handleEdit = (row) => {
            initData.title = row ? '编辑账号' : '新建账号';
            initData.Form = row ? JSON.parse(JSON.stringify(row)) : {};
            DialogRef.value.handleOpen();
        };

        const handleCancle = (row) => {
            formRef.value.resetFields();
        };

        //新建弹框确定
        const handleConfirm = () => {
            formRef.value.validate().then(() => {
                initData.Form.password = initData.Form.pwd ? md5(initData.Form.pwd) : '';
                delete initData.Form.pwd;
                proxy.http.post('/admin.admin/save', initData.Form).then(() => {
                    DialogRef.value.handleCancel();
                    getList();
                })
            })
        };

        //删除
        const handleDelete = (row) => {
            useDeleteFun('/admin.admin/delete', [row.id]).then(() => {
                getList();
            })
        }

        return {
            ...toRefs(initData),
            getList,
            filterChange,
            DialogRef,
            formRef,
            changeTableRequest,
            handleEdit,
            handleDelete,
            handleCancle,
            handleConfirm
        }
    }
})
</script>
